@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: orange-squash;
  src: url('assets/fonts/Orange Squash Free.ttf');
}

@font-face {
  font-family: super-senior;
  src: url('assets/fonts/Super Senior Personal Use.ttf');
}

@font-face {
  font-family: trushdex;
  src: url('assets/fonts/Trushdex.ttf');
}

* {
  scroll-behavior: smooth;
  user-select: none;
}

@layer utilities {
  .animation-delay-2000 {
    animation-delay: 2s;
  }

  .animation-delay-4000 {
    animation-delay: 4s;
  }
}

/* Hide scrollbar */
.hide-scrollbar {
  scrollbar-width: none;
  /* Firefox */
  -ms-overflow-style: none;
  /* Internet Explorer and Microsoft Edge */
}

.hide-scrollbar::-webkit-scrollbar {
  width: 0.5rem;
  /* Adjust as needed */
  background: transparent;
}

.hide-scrollbar::-webkit-scrollbar-thumb {
  background: transparent;
}

.active {
  color: #8ad411;
  transition: all;
  transition-duration: 0.2s;
}